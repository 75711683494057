/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pass-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 0a2 2 0 11-4 0H3.5A1.5 1.5 0 002 1.5v13A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0012.5 0zM4.5 5h7a.5.5 0 010 1h-7a.5.5 0 010-1m0 2h4a.5.5 0 010 1h-4a.5.5 0 010-1"/>',
    },
});
